jQuery(function($){

  //===============================================
  /* CMS向けにブロック要素に背景画像を設定 */
  //===============================================
  var d = document.getElementsByClassName("js-background");
  for(var i=0; i<d.length; i++){
    var f=d[i].getAttribute("data-src");
    d[i].style.backgroundImage="url('"+f+"')";
  };

  //===============================================
  /* pagetopボタン */
  //===============================================
  $('#btn_pagetop').click(function() {
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = 500; // スクロールの速度（ミリ秒）
    $('body,html').animate({scrollTop:position}, speed, 'swing'); // スクロール方式
    return false;
 });

 //===============================================
/* トップに戻るボタンなどの制御 */
//===============================================
var topBtn = $(".js-btn_pagetop");
var siteLogo = $(".js-header_logo");

$(window).on("scroll", function() {
  view_btn_top();
});
$(window).on('load resize', function(){
  view_btn_top();
});

function view_btn_top() {
  setTimeout(function(){
    if ($(this).scrollTop() > 150) {
      siteLogo.addClass('scrolled');
      topBtn.addClass('show');
    } else {
      siteLogo.removeClass('scrolled');
      topBtn.removeClass('show');
    }
  },100);
}
  //===============================================
  /* スマホでのスクロール制御 */
  //===============================================
  var scrollControll = function(event) {
    if ($(event.target).closest('#drawer').length > 0 || $(event.target).closest('#drawer_sub').length > 0 ) {
      event.stopPropagation();
    } else {
      event.preventDefault();
    }
  };

  $('.js-chg_daddress').on('click', function(){
    $('#dbox1').removeClass('show');
    $('#dbox2').addClass('show');   
  });

  $('input[name="daddress"]').change(function() {
    var result = $(this).val();
    if(result == 'xxx') {
      $('.form_regist').addClass('show');
    }else{
      $('.form_regist').removeClass('show');
    }
  });
  
  //===============================================
  /* ドロワーナビ展開 */
  //===============================================
  $('.js_btn_drawer').on('click',function(){
    if($(this).hasClass('active')) {
      $('#drawer').fadeOut();
      $('body').removeClass('fixed');
      $(this).removeClass('active');
      $('#overlay').removeClass('active');
      document.removeEventListener(
        'touchmove',
        scrollControll,
        {
          passive: false
        }
      );
    }else{
      $('#drawer').fadeIn();
      $('body').addClass('fixed');
      $(this).addClass('active');
      $(this).removeClass('behind');
      $('.js_btn_search_area').addClass('behind');
      $('#overlay').addClass('active');
      document.addEventListener(
        'touchmove',
        scrollControll,
        {
          passive: false
        }
      );
    }
  });

  $('a:not(.js_btn_drawer)').click(function(event) {
    $('body').removeClass('fixed');
    $('.js_btn_drawer').removeClass('active');
    $('.js_btn_search_area').removeClass('active');
    $('#overlay').removeClass('active');
    $('#drawer').fadeOut();
    $('#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      scrollControll,
      {
        passive: false
      }
    );
  });

  //オーバーレイのクリック時の処理（ドロワーナビを閉じる）
  $('#overlay').on('click touchstart',function(){
    $('body').removeClass('fixed');
    $('.js_btn_drawer,.js_btn_drawer_sub,.js_btn_search_area').removeClass('active');
    $(this).removeClass('active');
    $('#drawer,#drawer_sub,#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      scrollControll,
      {
        passive: false
      }
    );
  });

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}
